
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
// Data
import data from "layouts/transactions/components/Transactions/data";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSandbox } from "ZustandState/useSanbox";
import formatDateAndTime from "util/formatDateAndTime";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useMaterialUIController } from "context";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Grid } from "@mui/material";
import useAuthFetch from "util/apiFetcher";
import MDButton from "components/MDButton";
function Transactions({ recieveStatsFunc }) {
    const authFetch = useAuthFetch()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const sandbox = useSandbox((state) => state.sandbox)
    const [transactions, setTransactions] = useState([])
    const [totalTransaction, setTotaltransaction] = useState([])
    const [page, setPage] = useState(0)
    const [check, setCheck] = useState("all")
    const [currentStatus, setCurrentStatus] = useState("")
    const [reload, setReload] = useState(true)
    const [isGeneratebtn, setIsGeneratebtn] = useState(true)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [menu, setMenu] = useState(false);
    const closeMenu = () => setMenu(false);
    const [activeMerchant, setActiveMerchant] = useState('all')
    const [merchantInfo, setMerchantInfo] = useState([])
    const { columns, rows } = data(transactions, activeMerchant !== "all" ? merchantInfo.find(m => m.id === activeMerchant).business_name : "");
    let limit = 10;
    const formikRef = React.createRef();

    const handleResetForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };
    const initialSearchValues = {
        search: '',
    };
    const initialDateValues = {
        startDate: '',
        endDate: ''
    };
    const toggleMenu = ({ currentTarget }) => {
        if (menu) {
            setMenu(false)
        } else {
            setMenu(currentTarget)
        }
    }
    const [formikValues, setFormikValues] = useState(initialDateValues);
    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => {


                setCsvCompleteData([])
                setCheck("all")
                setCurrentStatus("")
                setPage(0)
                setIsGeneratebtn(true)
                setReload(!reload)
                formikValues.startDate !== "" && formikValues.endDate !== "" && setFormikValues({ ...formikValues, startDate: "", endDate: "" })
                setMenu(null);
                closeMenu()
                handleResetForm()

            }}>
                All
            </MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("success")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.startDate !== "" && formikValues.endDate !== "" ? activeMerchant === "all" ? handleDateSearchForAll(formikValues, "success") : handleDateSearchForMerchant(formikValues, "success") : filterSearch("success")
                // handleResetForm()
            }}>Success</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("pending")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.startDate !== "" && formikValues.endDate !== "" ? activeMerchant === "all" ? handleDateSearchForAll(formikValues, "pending") : handleDateSearchForMerchant(formikValues, "pending") : filterSearch("pending")

                // handleResetForm()
            }}>Pending</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("failed")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.startDate !== "" && formikValues.endDate !== "" ? activeMerchant === "all" ? handleDateSearchForAll(formikValues, "failed") : handleDateSearchForMerchant(formikValues, "failed") : filterSearch("failed")
                // handleResetForm()
            }}>Failed</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("expired")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.startDate !== "" && formikValues.endDate !== "" ? activeMerchant === "all" ? handleDateSearchForAll(formikValues, "expired") : handleDateSearchForMerchant(formikValues, "expired") : filterSearch("expired")
                // handleResetForm()
            }}>Expired</MenuItem>
        </Menu>
    );

    //on change of active merchant get stats of activeMerchant
    const getActiveMerchantStats = async () => {
        try {
            const res = await authFetch(`/merchantPayinStats/${activeMerchant}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            recieveStatsFunc(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    // With this we can find a single transaction by utr globally over all merchant
    const handleSearch = async (values) => {
        try {
            if (values.search === '') {
                return;
            }
            const res = await authFetch(`/getPayinTxBySearch?search=${values.search}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setTransactions(res.responseData)
        } catch (err) {
            console.log("Error Fetching handleSearch: ", err)
        }
    }
    // with this we can get all the merchant info for dropdown 
    const getAllMerchant = async () => {
        try {
            const res = await authFetch(`/payinActiveUsers`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setMerchantInfo(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllMerchant: ", err)
        }
    }
    // with this we get date wise transaction of all merchant for table of status all or any status
    const handleDateSearchForAll = async (values, status = "") => {
        try {
            if (activeMerchant !== "all") return;
            if (values.startDate === "" || values.endDate === "") return;

            if (values.endDate < values.startDate || values.startDate > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            if (status !== "") {
                values.status = status
            } else {
                values.status = "all"
            }
            setCheck("date")
            status === "" && setCurrentStatus("")
            setCsvCompleteData([])
            const res = await authFetch(`/getAllTransactionDatewise?startDate=${values.startDate}&endDate=${values.endDate}&status=${values.status}&limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setTransactions(res.responseData)
            setIsGeneratebtn(true)
        } catch (err) {
            console.log("Error Fetching handleDateSearchForAll: ", err)
        }
    }

    // with this we get date wise transaction of all merchant for csv of status all or any status
    const handleDateSearchForAllForCsv = async (values) => {
        try {
            if (values.startDate === "" || values.endDate === "") return;

            if (values.endDate < values.startDate || values.startDate > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            const res = await authFetch(`/getAllTransactionDatewise?startDate=${values.startDate}&endDate=${values.endDate}&status=${values.status}&limit=${values.limit}&skip=${values.limit * values.csvPage}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            return res.responseData
        } catch (err) {
            console.log("Error Fetching handleDateSearchForAllForCsv: ", err)
        }
    }

    // with this we get date wise transaction of single merchant for table of status all or any status
    const handleDateSearchForMerchant = async (values, status = "") => {
        try {
            if (activeMerchant === "all") return;
            if (values.startDate === "" || values.endDate === "") return;

            if (values.endDate < values.startDate || values.startDate > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            if (status !== "") {
                values.status = status
            } else {
                values.status = "all"
            }
            setCheck("date")
            status === "" && setCurrentStatus("")
            setCsvCompleteData([])
            const res = await authFetch(`/getAllMerchantTransactonDatewise?id=${activeMerchant}&startDate=${values.startDate}&endDate=${values.endDate}&status=${values.status}&limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setTransactions(res.responseData)
            setIsGeneratebtn(true)
        } catch (err) {
            console.log("Error Fetching handleDateSearchForMerchant: ", err)
        }
    }

    // with this we get date wise transaction of single merchant for csv of status all or any status
    const handleDateSearchForMerchantForCsv = async (values) => {
        try {
            if (values.merchant === "all") return;
            if (values.startDate === "" || values.endDate === "") return;

            if (values.endDate < values.startDate || values.startDate > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            const res = await authFetch(`/getAllMerchantTransactonDatewise?id=${values.merchant}&startDate=${values.startDate}&endDate=${values.endDate}&status=${values.status}&limit=${values.limit}&skip=${values.limit * values.csvPage}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            return res.responseData
        } catch (err) {
            console.log("Error Fetching handleDateSearchForMerchantForCsv: ", err)
        }
    }
    // when currentStatus === "" and activeMerchant === "all" for table
    const getAllTransactions = async () => {
        try {
            const res = await authFetch(`/getAllPayins?limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentStatus === "" and activeMerchant === "all" for csv
    const getAllTransactionsForCsv = async (values) => {
        try {
            const res = await authFetch(`/getAllPayins?limit=${values.limit}&skip=${values.limit * values.csvPage}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching getAllTransactionsForCsv: ", err)
        }
    }
    // when currentstatus ==="" and for single merchant for table (finding all the transaction of single merchant)
    const getSingleMerchantAllTransaction = async () => {
        try {
            const res = await authFetch(`/merchantPayinData/${activeMerchant}?limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentstatus ==="" and for single merchant for csv (finding all the transaction of single merchant)
    const getSingleMerchantAllTransactionForCsv = async (values) => {
        try {
            const res = await authFetch(`/merchantPayinData/${values.merchant}?limit=${values.limit}&skip=${values.limit * values.csvPage}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching getSingleMerchantAllTransactionForCsv: ", err)
        }
    }

    // when we are finding transactions of any mercant by status (success, pending, failed, expired) for table
    const filterSearch = async (status) => {
        try {
            setCurrentStatus(status)
            setCheck("status")
            const res = await authFetch(`/getPayinTxByFilter?merchantId=${activeMerchant}&status=${status}&limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            closeMenu()
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching filterSearch: ", err)
        }
    }
    // when we are finding transactions of any mercant including all by status (success, Pending,failed) for csv
    const filterSearchForCsv = async (values) => {
        try {
            const res = await authFetch(`/getPayinTxByFilter?merchantId=${values.merchant}&status=${values.status}&limit=${values.limit}&skip=${values.limit * values.csvPage}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            return res.responseData
        } catch (err) {
            console.log("Error Fetching filterSearchForCsv: ", err)
        }
    }

    const afterCsvDownload = () => {
        setCsvCompleteData([]);
        setIsGeneratebtn(true)
    }
    const generateCsv = async () => {
        setIsGeneratebtn(false)
        let csvPage = 0
        // getting all the transactions of all the merchant and status is all ("")
        if (activeMerchant === "all" && currentStatus === "" && check === "all") {
            let responseData = true
            do {
                let values = {
                    limit: 500,
                    csvPage
                }
                await getAllTransactionsForCsv(values).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        } // getting all the transaction of single merchant , if currentStatus === "" and active merchant has something then we have to find all the transaction of that merchant
        else if (currentStatus === "" && activeMerchant !== "all" && check === "all") {
            let responseData = true
            do {
                let values = {
                    merchant: activeMerchant,
                    limit: 500,
                    csvPage
                }
                await getSingleMerchantAllTransactionForCsv(values).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        } // getting all the transaction by status wise for a single merchant and all merchant 
        else if (check === "status" && currentStatus !== "") {
            let responseData = true
            do {
                let values = {
                    merchant: activeMerchant,
                    status: currentStatus,
                    limit: 500,
                    csvPage
                }

                await filterSearchForCsv(values).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        } // getting all the transaction by date wise (for all status or for any specific status) for a all merchant
        else if (check === "date" && activeMerchant === "all") {
            let responseData = true
            do {
                let values = {
                    status: currentStatus === "" ? "all" : currentStatus,
                    startDate: formikValues.startDate,
                    endDate: formikValues.endDate,
                    limit: 500,
                    csvPage
                }

                await handleDateSearchForAllForCsv(values).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        } else if (check === "date" && activeMerchant !== "all") {
            let responseData = true
            do {
                let values = {
                    status: currentStatus === "" ? "all" : currentStatus,
                    merchant: activeMerchant,
                    startDate: formikValues.startDate,
                    endDate: formikValues.endDate,
                    limit: 500,
                    csvPage
                }

                await handleDateSearchForMerchantForCsv(values).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        }
        else {
            toast.error("Something is wrong ! Please Select a merchant ")
            setIsGeneratebtn(true)
            return
        }
        // Now that you have all the data in csvCompleteData, remove duplicates based on the _id field.
        const uniqueCsvCompleteData = csvCompleteData.filter((value, index, self) => {
            const firstIndex = self.findIndex((item) => item._id === value._id);
            return index === firstIndex;
        });
        // Set csvCompleteData to the unique data.
        setCsvCompleteData(uniqueCsvCompleteData)
        // Create the CSV content
        const csvContent = [
            ["Time", "Date", "Merchant", "Gateway", "Gross Amount", "TDS", "Net Amount", "USDT Rate", "Payout Address", "status", "Customer ID", "UTR", "TXID"],
            ...csvCompleteData.map(({ transaction_date, amount, taxDeducted, amountReceived, usdt_rate, payout_address, customer_id, business_name, gateway, status, utr, transactionId }) => [
                formatDateAndTime(transaction_date).formattedTime || '',
                formatDateAndTime(transaction_date, true).formattedDate || '',
                business_name ? business_name : merchantInfo.find(m => m.id === activeMerchant)?.business_name,
                gateway,
                amount || '',
                taxDeducted || '',
                amountReceived || '',
                usdt_rate || '',
                payout_address || '',
                status ? status === "IN-PROCESS" ? "pending" : status === "fail" ? "failed" : status.toLowerCase() : '',
                customer_id || '',
                utr || '',
                transactionId || ''
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'payin.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        afterCsvDownload()
    }


    useEffect(() => {
        handleResetForm()
        if (activeMerchant !== "all") {
            getActiveMerchantStats()
        }
        else if (activeMerchant === "all") {
            recieveStatsFunc("all")
        }
    }, [activeMerchant, sandbox])


    useEffect(() => {
        if (check !== "date" && activeMerchant !== 'all' && currentStatus === "") {
            getSingleMerchantAllTransaction()
        } else if (activeMerchant !== "all" && check === "date") {
            handleDateSearchForMerchant(formikValues, currentStatus === "" ? "" : currentStatus)
        } else if (activeMerchant === "all" && check === "date") {
            handleDateSearchForAll(formikValues, currentStatus === "" ? "" : currentStatus)
        }
        else if (check !== "date" && activeMerchant === 'all' && currentStatus === "") {
            getAllTransactions()
        }
        else if (currentStatus !== "all" && check === "status") {
            filterSearch(currentStatus)
        }
    }, [activeMerchant, page, reload, sandbox])

    useEffect(() => {
        if (merchantInfo.length === 0) {
            getAllMerchant()
        }
        if (check === "all" && activeMerchant === "all") {
            getAllTransactions()
        }
    }, [page, reload, sandbox])

    const resetFilters = () => {
        setPage(0)
        setCheck("all")
        setFormikValues({ ...formikValues, startDate: "", endDate: "" })
        setActiveMerchant("all")
        setCurrentStatus("")
        setIsGeneratebtn(true)
        setCsvCompleteData([])
        handleResetForm()
        setReload(!reload)
    }


    return (

        <Card>
            <MDBox display={'flex'} flexDirection={'column'}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={1}>

                    <Grid container alignItems="center" p={1}>
                        {/* <Grid item xl={4}>
                            <MDBox ml={1} alignItems="center">
                                <MDTypography variant="h6" gutterBottom>
                                    {check === "all" ? 'ALL ' : check === "date" ? 'DATE WISE ' : currentStatus.toUpperCase()} TRANSACTIONS
                                    PAYIN TRANSACTIONS
                                </MDTypography>
                            </MDBox>
                        </Grid> */}
                        <Grid item xl={12}>
                            <Grid container spacing={2}>
                                <Grid item xl={4} sm={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xl={6} xs={5.5} sm={6}>
                                            {/* selectMerchant */}
                                            <Formik
                                                initialValues={{ active_merchant: '' }}
                                            >
                                                {({ }) => (
                                                    <Form>
                                                        <MDBox mb={0.5} >
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Select Merchant
                                                            </MDTypography>
                                                            <Field
                                                                type="text"
                                                                select
                                                                size="large"
                                                                as={MDInput}
                                                                fullWidth
                                                                value={activeMerchant}
                                                                onChange={(e) => {
                                                                    e.target.value !== "" && setActiveMerchant(e.target.value); setPage(0); setCurrentStatus(''); setCheck('all'); setCsvCompleteData([]); setIsGeneratebtn(true);
                                                                    setFormikValues({ ...formikValues, startDate: "", endDate: "" })

                                                                }}
                                                                name="active_merchant"
                                                                InputProps={{
                                                                    classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                }}
                                                            >

                                                                {/* <MenuItem value="">Select Merchant</MenuItem> */}
                                                                <MenuItem value="all">All</MenuItem>
                                                                {merchantInfo && merchantInfo.map((merchant, index) => (
                                                                    <MenuItem key={index} value={merchant.id}>
                                                                        {merchant.business_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </MDBox>


                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                        <Grid item xl={6} xs={6.5} sm={6}>
                                            {/* search input */}
                                            <Formik
                                                initialValues={initialSearchValues}
                                                onSubmit={handleSearch}
                                            >
                                                {({ setFieldValue }) => (
                                                    <Form>
                                                        <MDBox mb={0.5}>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Search by UTR or TXID
                                                            </MDTypography>
                                                            <MDBox>
                                                                <Grid container>
                                                                    <Grid item xl={10} lg={11} md={11} sm={11} xs={11}>
                                                                        <Field
                                                                            onChange={(e) => {
                                                                                setFieldValue('search', e.target.value)
                                                                                if (initialSearchValues.search === '') {
                                                                                    setTransactions(totalTransaction)
                                                                                }
                                                                            }}
                                                                            type="text" as={MDInput} name="search" fullWidth />
                                                                    </Grid>
                                                                    <Grid item xl={2} lg={1} md={1} sm={1} xs={1}>
                                                                        <IconButton type="submit">
                                                                            <SearchIcon color={darkMode ? "white" : "black"} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>


                                                            </MDBox>
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="search" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={4} sm={12} xs={12} >
                                    {/* date input */}
                                    <Formik
                                        initialValues={initialDateValues}
                                        innerRef={formikRef}
                                    >
                                        {({ setFieldValue }) => (
                                            <Form>
                                                <Grid container spacing={1}>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <MDBox>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Start date
                                                            </MDTypography>
                                                            <Field
                                                                size="large"
                                                                onChange={async (e) => {
                                                                    await setFieldValue('startDate', e.target.value)
                                                                    const newFormikValues = { ...formikValues, startDate: e.target.value };
                                                                    setFormikValues(newFormikValues);
                                                                    setPage(0);
                                                                    activeMerchant === "all" ? handleDateSearchForAll(newFormikValues) : handleDateSearchForMerchant(newFormikValues);
                                                                }}
                                                                type="date" as={MDInput} fullWidth name="startDate"
                                                            />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="startDate" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <MDBox>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                End date
                                                            </MDTypography>
                                                            <Field
                                                                size="large"
                                                                onChange={async (e) => {
                                                                    await setFieldValue('endDate', e.target.value)
                                                                    const newFormikValues = { ...formikValues, endDate: e.target.value };
                                                                    setFormikValues(newFormikValues);
                                                                    setPage(0);
                                                                    activeMerchant === "all" ? handleDateSearchForAll(newFormikValues) : handleDateSearchForMerchant(newFormikValues);
                                                                }}
                                                                type="date" as={MDInput} fullWidth name="endDate" />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="endDate" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>

                                <Grid item xl={4} sm={12} xs={12}>
                                    <Grid display={"flex"} flexDirection={"row"} mt={4} container >
                                        {/* filter */}
                                        <MDBox px={0.5}>

                                            <MDButton onClick={toggleMenu} color="success" >
                                                Filter
                                                <TuneOutlinedIcon sx={{ cursor: "pointer", fontWeight: "bold", marginLeft: '0.5vw' }} fontSize="medium" />
                                                {renderMenu}
                                            </MDButton>
                                        </MDBox>

                                        {
                                            isGeneratebtn === true ? (
                                                <MDBox px={0.5}>
                                                    <MDButton size="medium" color='info' onClick={() => generateCsv()}>
                                                        Download
                                                        <FileDownloadOutlinedIcon sx={{ cursor: "pointer", fontWeight: "bold", marginLeft: '0.5vw' }} fontSize="medium" />
                                                    </MDButton>
                                                </MDBox>
                                            ) : (
                                                <MDTypography ml={4} mt={1.5} variant="caption" color="info">
                                                    <BeatLoader
                                                        color="#36d7b7"
                                                        cssOverride={{}}
                                                        size={15}
                                                    />
                                                </MDTypography>
                                            )
                                        }
                                        <MDBox px={0.5}>
                                            <MDButton onClick={resetFilters} size="medium" color='dark'>
                                                Reset
                                                <RefreshIcon sx={{ cursor: "pointer", fontWeight: "bold", marginLeft: '0.5vw' }} fontSize="medium" />
                                            </MDButton>
                                        </MDBox>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <MDBox alignItems="center" color="text" px={2} display={'flex'} flexDirection={'row'}>
                    </MDBox> */}
                </MDBox>
                <MDBox>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                        entriesPerPage={false}
                        pages={page}
                        pageSetter={setPage}
                    />
                </MDBox>
            </MDBox>
        </Card>

    );
}

Transactions.propTypes = {
    recieveStatsFunc: PropTypes.func
}
export default Transactions;

