/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useConfig } from "../../../config"
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { TextField } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
// import { useUser } from "ZustandState/useUser";
import { useMaterialUIController } from 'context';
import { useSandbox } from 'ZustandState/useSanbox';
import { useNavigate } from 'react-router-dom';
// Data
function addMerchant() {
    const sandbox = useSandbox((state) => state.sandbox)
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const adminToken = localStorage.getItem('gpay_admin_token')
    // const user = useUser((state) => state.user)
    const [openDialog, setOpenDialog] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const { apiUrl, apiKey } = useConfig()
    const navigate = useNavigate()
    const [gateways, setGateways] = useState([])
    const validationSchema = Yup.object({
        email_id: Yup.string().required('Email is required'),
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        business_name: Yup.string().required('Business Name is required'),
        // business_type: Yup.string().required('Business Type is required'),
        // gateway: Yup.string().required('gateway is required'),

    });

    const initialValues = {
        email_id: '',
        first_name: '',
        last_name: '',
        business_name: '',
        // business_type: '',
        // gateway: ''
    };
    // with this we can get all the gateway info for dropdown 
    // const getAllGateways = async () => {
    //     let body = {
    //         emailId: adminEmail || '',
    //         apiKey: adminApiKey || '',
    //     }
    //     try {
    //         const response = await fetch(`${apiUrl}/admin/getallgateways`, {
    //             method: 'POST',
    //             headers: {
    //                 //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(body)
    //         })

    //         const res = await response.json()
    //         if (res.responseCode !== 200) return;
    //         setGateways(res?.responseData)
    //     } catch (err) {
    //         console.log("Error Fetching getAllGateways: ", err)
    //     }
    // }
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch(`${apiUrl}/user/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${adminToken}`,
                    'apikey': apiKey
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode === 403) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);

            const userBody = {
                emailId: res.responseData.email,
                password: res.responseData.password,
                apiKey: res.responseData.apiKey
            }
            setResponseData(userBody);
            setOpenDialog(true);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCopyToClipboard = () => {

        const dataToCopy = `Address: https://gsxsolutions.com\nEmail id: ${responseData.emailId}\nPassword: ${responseData.password}\nApikey: ${responseData.apiKey}`;
        navigator.clipboard.writeText(dataToCopy);
        toast.success('User data copied to clipboard', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
        });
    };
    // useEffect(() => {
    //     getAllGateways()
    // }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                        <MDTypography variant="h4" gutterBottom align="center" mb={4}>
                            Registration
                        </MDTypography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                name="first_name"
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                label="First name"
                                                helperText={<ErrorMessage name="first_name" />}
                                                error={Boolean(ErrorMessage.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                name="last_name"
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                label="Last name"
                                                helperText={<ErrorMessage name="last_name" />}
                                                error={Boolean(ErrorMessage.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="email_id"
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                label="Email address"
                                                helperText={<ErrorMessage name="email_id" />}
                                                error={Boolean(ErrorMessage.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="business_name"
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                label="Business Name"
                                                helperText={<ErrorMessage name="business_name" />}
                                                error={Boolean(ErrorMessage.name)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <MDBox mt={4} mb={1} display="flex" justifyContent="center">
                                        <MDButton
                                            variant="contained"
                                            color="dark"
                                            type="submit"
                                            disabled={isSubmitting}
                                            fullWidth='true'

                                        >
                                            {isSubmitting ? 'Registering...' : 'Register'}
                                        </MDButton>
                                    </MDBox>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Container>

            </MDBox>
            {/* Response Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>User Data</DialogTitle>
                <DialogContent>

                    <div>
                        <div>Address: https://gsxsolutions.com</div>
                        <div>Email id: {responseData?.emailId}</div>
                        <div>Password: {responseData?.password}</div>
                        <div>Apikey: {responseData?.apiKey}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyToClipboard} color="primary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default addMerchant;
