import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import HistoryOverview from 'layouts/exchangeRate/history'
import { useMaterialUIController } from 'context'
import { useConfig } from "../../config"
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
import MDButton from 'components/MDButton'
import { Link } from 'react-router-dom'
function exchangeRate() {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const { apiUrl, apiKey } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const [page, setPage] = useState(0)
    const limit = 10
    const adminToken = localStorage.getItem('gpay_admin_token')
    const [exchangeRateList, setExchangeRateList] = useState([])
    const [loading, setLoading] = useState(true)

    const getRateHistory = async () => {
        try {
            const response = await fetch(`${apiUrl}/admin/getUsdtRates?limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'apikey': apiKey,
                    'Content-Type': 'application/json',
                },
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setExchangeRateList(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getRateHistory: ", err)
        }
    }
    useEffect(() => {
        getRateHistory().then(() => {
            setLoading(false)
        })
    }, [sandbox, page])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <MDBox mt={1}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12} lg={12}>
                            <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Exchange Rate History</MDTypography>
                                <Link to={"/update-rate"} state={exchangeRateList.length > 0 ? exchangeRateList[0].usdtRate : 0}>
                                    <MDButton color="success" mr={5}>
                                        Update Exchange Rate
                                    </MDButton>
                                </Link>
                            </MDBox>

                            {
                                !loading && (
                                    <MDBox
                                        mt={2}
                                    >
                                        <HistoryOverview list={exchangeRateList} page={page} pageSetter={setPage} />
                                    </MDBox>
                                )
                            }
                            {
                                loading && (
                                    <Loader />
                                )
                            }
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}

export default exchangeRate