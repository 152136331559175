import { useSandbox } from "ZustandState/useSanbox";
export const useConfig = () => {
    const { sandbox } = useSandbox();

    const config = {
        apiUrl: sandbox ? 'https://sandbox.gsxsolutions.com' : 'https://api.gsxsolutions.com',
        supportUrl: 'https://support-api.gsxsolutions.com',
        apiKey: "U2FsdGVkX18v+AiAL4nA4O10exYvxi+jR3KKhsZUqkc="
    };

    return config;
}