
import PropTypes from 'prop-types';
import { useMaterialUIController } from 'context';
import DataTable from './DataTable';
import dataFormat from "layouts/merchantTopup/topupLogs/data";
function topupLogs(props) {
    const { pages, pageSetter, logs, downloadInvoice } = props
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const { columns, rows } = dataFormat(logs, downloadInvoice);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            pages={pages}
            pageSetter={pageSetter}
        />
    );
}

topupLogs.propTypes = {
    pages: PropTypes.number,
    pageSetter: PropTypes.func,
    logs: PropTypes.any,
    downloadInvoice: PropTypes.func
};

export default topupLogs;
