
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import { useConfig } from "../../../config"
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { TextField } from '@mui/material';
import { useMaterialUIController } from 'context';
import { useSandbox } from 'ZustandState/useSanbox';
import { useNavigate } from 'react-router-dom';
// Data
function addMerchant() {
    const sandbox = useSandbox((state) => state.sandbox)
    const [controller] = useMaterialUIController()
    const navigate = useNavigate()
    const { darkMode } = controller
    const adminToken = localStorage.getItem('gpay_admin_token')
    // const user = useUser((state) => state.user)
    const { apiUrl, apiKey } = useConfig()
    const validationSchema = Yup.object({
        gatewayName: Yup.string().required('Gateway name is required'),
        abbr: Yup.string().required('Abbrevation is required'),
    });

    const initialValues = {
        gatewayName: '',
        abbr: '',
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch(`${apiUrl}/admin/addGateway`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${adminToken}`,
                    'apikey': apiKey
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode === 403) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                        <MDTypography variant="h4" gutterBottom align="center" mb={4}>
                            Add Gateway
                        </MDTypography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="gatewayName"
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                label="Gateway Name"
                                                helperText={<ErrorMessage name="gatewayName" />}
                                                error={Boolean(ErrorMessage.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name="abbr"
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                label="Abbreviation"
                                                helperText={<ErrorMessage name="abbr" />}
                                                error={Boolean(ErrorMessage.name)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <MDBox mt={4} mb={1} display="flex" justifyContent="center">
                                        <MDButton
                                            variant="contained"
                                            color="dark"
                                            type="submit"
                                            disabled={isSubmitting}
                                            fullWidth='true'

                                        >
                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                        </MDButton>
                                    </MDBox>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Container>

            </MDBox>

        </DashboardLayout>
    );
}

export default addMerchant;
