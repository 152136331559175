
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
export default function data(transactions, activemerchant) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "time", accessor: "time", align: "left" },
            { Header: "date", accessor: "date", align: "left" },
            { Header: "merchant", accessor: "merchant", align: "left" },
            { Header: "gateway", accessor: "gateway", align: "left" },
            { Header: "gross amount", accessor: "amount", align: "right" },
            { Header: "tds", accessor: "tax", align: "right" },
            { Header: "net amount", accessor: "amountRecieved", align: "right" },
            { Header: "usdtrate", accessor: "usdtRate", width: "15%", align: "right" },
            { Header: "payout address", accessor: "payoutAddress", width: "15%", align: "left" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "customer id", accessor: "customerId", align: 'left', width: "10%", },
            { Header: "UTR", accessor: "utr", width: "10%", align: 'left', },
            { Header: "TXID", accessor: "txid", width: "10%", align: 'left', },
        ],

        rows: transactions && transactions.map((item, index) => ({
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item?.transaction_date).formattedTime || ''}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item.transaction_date).formattedDate || ''}
                </MDTypography>
            ),
            merchant: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.business_name || activemerchant}
                </MDTypography>
            ),
            gateway: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.gateway || ''}
                </MDTypography>
            ),
            amount: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.amount || ''}
                </MDTypography>
            ),
            tax: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.taxDeducted || ''}
                </MDTypography>
            ),
            amountRecieved: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.amountReceived || ''}
                </MDTypography>
            ),
            usdtRate: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.usdt_rate || ''}
                </MDTypography>
            ),
            payoutAddress: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.payout_address || ''}
                </MDTypography>
            ),
            status: item && (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={item?.status === "IN-PROCESS" ? "PENDING" : item?.status === "fail" ? "FAILED" : item?.status} color={item?.status === "Success" || item?.status === "success" || item?.status === "SUCCESS" ? "success" : item?.status === "IN-PROCESS" || item.status === "pending" ? "warning" : item?.status === "PENDING" ? "warning" : item.status === "expired" ? "info" : "error"} variant="gradient" size="sm" />
                </MDBox>
            ),
            customerId: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item.customer_id || ''}
                </MDTypography>
            ),
            utr: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.utr || ''}
                </MDTypography>
            ),
            txid: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.transactionId || ''}
                </MDTypography>
            ),
        }))
    }
}
