import { Grid } from '@mui/material'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import { useEffect, useState } from 'react'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import TopupLogs from "layouts/merchantTopup/topupLogs"
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import formatDateAndTime from "util/formatDateAndTime";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import { PDFDocument, rgb } from 'pdf-lib';
import logo from "assets/images/gpayb-removebg-preview.png"
import { capitalizeFirstLetter } from 'util/formatDateAndTime'
import { useNavigate } from 'react-router-dom'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
import useAuthFetch from 'util/apiFetcher'
function topupMerchant() {
    const navigate = useNavigate()
    const authFetch = useAuthFetch()
    const { state } = useLocation()
    const sandbox = useSandbox((state) => state.sandbox)
    const limit = 10
    const [page, setPage] = useState(0)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const validationSchema = Yup.object({
        grossAmount: Yup.number().required('Gross Amount is required'),
        netFeesInPercentage: Yup.number().min(0).max(100).required('Fees Percentage is required'),
        usdtRate: Yup.number(),
        ref: Yup.string(),
    });
    const [topupLogs, setTopupLogs] = useState([])
    const [controller] = useMaterialUIController()
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const initialValues = {
        grossAmount: '',
        netFeesInPercentage: '',
        usdtRate: '',
        ref: '',
    }
    const [payoutBalance, setPayoutBalance] = useState(0)
    const [netFees, setNetFees] = useState(0)
    const [usdtNet, setUsdtNet] = useState(0)
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => {
        setNetFees(0)
        setPayoutBalance(0)
        setUsdtNet(0)
        setOpenDialog(false);

    };
    const downloadPdfInvoice = async (log) => {
        const pdfDoc = await PDFDocument.create();
        // Set page size to A4
        const page = pdfDoc.addPage([595, 842]); // 595 x 842 are the dimensions for A4 in points
        // Add left border Line
        page.drawLine({ start: { x: 8, y: 830 }, end: { x: 8, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
        // Add right border Line
        page.drawLine({ start: { x: 587, y: 830 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
        // Add upper border Line
        page.drawLine({ start: { x: 8, y: 830 }, end: { x: 587, y: 830 }, thickness: 1, color: rgb(0, 0, 0) });
        // Add bottom border Line
        page.drawLine({ start: { x: 8, y: 12 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });

        // Add Company Logo
        const logoUrl = logo; // Replace with the actual path or URL to your logo
        const logoImageBytes = await fetch(logoUrl).then(res => res.arrayBuffer());
        const logoImage = await pdfDoc.embedPng(logoImageBytes);
        page.drawImage(logoImage, { x: 380, y: 770, width: 200, height: 65 });

        // Add Merchant Information
        const merchantInfo = [
            { title: 'Merchant Name', value: capitalizeFirstLetter(data?.business_name) },
            { title: 'Topup Date', value: formatDateAndTime(log.transaction_date).formattedDate || '' },
            { title: 'Topup No.', value: (log.txIndex).toString() },
        ];

        merchantInfo.forEach(({ title, value }, index) => {
            page.drawText(`${title}:`, { x: 50, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
            page.drawText(value, { x: 350, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
        });

        // Add Settlement Report
        page.drawRectangle({ x: 50, y: 580, width: 500, height: 30, color: rgb(0, 0, 0) });
        page.drawText('Topup Report', { x: 200, y: 587, fontSize: 11, color: rgb(1, 1, 1) });

        // const feeCharged = log?.netFees;
        // const feeChargedValue = feeCharged !== undefined ? feeCharged.toFixed(0).toString() : '';

        // Add Data Section
        const dataSection = [
            { title: 'Gross Topup', value: (log?.grossAmount && log.grossAmount.toFixed(0) || '').toString() },
            { title: 'Gateway Fees', value: (log?.feesPercentage && log?.feesPercentage + '%' || '').toString() },
            { title: 'Net Gateway Fees', value: (log?.netFees && log.netFees.toFixed(2) || '').toString() },
        ];

        dataSection.forEach(({ title, value }, index) => {
            page.drawText(title, { x: 50, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
            page.drawText(value, { x: 400, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
        });

        // Add Footer Line
        page.drawLine({ start: { x: 50, y: 180 }, end: { x: 550, y: 180 }, thickness: 1, color: rgb(0, 0, 0) });

        // Add Footer Data
        const footerData = [
            { title: 'Net Topup', value: (log?.payoutBalance && log?.payoutBalance.toFixed(2) || '').toString() },
            { title: 'USDT Rate', value: (log?.usdtRate && log?.usdtRate || '').toString() },
            { title: 'USDT Net Topup', value: (log?.usdtNet?.toFixed(2)).toString() },
        ];

        footerData.forEach(({ title, value }, index) => {
            page.drawText(title, { x: 50, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
            page.drawText(value, { x: 400, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
        });

        let filename = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatDateAndTime(log.transaction_date).formattedDate}.pdf`
        // Save the PDF
        const pdfBytes = await pdfDoc.save();

        // Trigger download
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename
        link.click();
    }

    const getPayoutVolume = async () => {
        try {
            const res = await authFetch(`/merchantTopups/${state}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getPayoutVolume: ", err)
        }
    }
    const getTopupLogs = async () => {
        try {
            const res = await authFetch(`/getTopupLog/${state}?limit=${limit}&skip=${limit * page}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setTopupLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getTopupLogs: ", err)
        }
    }
    const getTopupLogsForCsv = async (values) => {
        try {
            const res = await authFetch(`/getTopupLog/${values.id}?limit=${values.limit}&skip=${values.limit * values.csvPage}`, {
                method: 'GET',
            }, false)
            if (!res) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching getTopupLogsForCsv: ", err)
        }
    }

    const handleTopup = async (values, { setSubmitting }) => {
        try {
            values.netFees = netFees
            values.payoutBalance = payoutBalance
            values.usdtNet = usdtNet
            const res = await authFetch(`/createTopup/${state}`, {
                method: 'POST',
                body: JSON.stringify(values)
            }, true)
            if (!res) return;
            setSubmitting(false);
            page > 0 ? (setPage(0), getPayoutVolume()) : (getTopupLogs(), getPayoutVolume())
            handleCloseDialog()
        } catch (error) {
            console.log("Error Fetching handleTopup: ", error)
            // Reset submitting state
            setSubmitting(false);
        }
    };

    const downloadTopupLogs = async () => {
        setLoading(true)
        setCsvCompleteData([])
        let csvPage = 0
        let responseData = true
        do {
            let values = {
                id: state || '',
                limit: 500,
                csvPage,
            }

            await getTopupLogsForCsv(values).then((res) => {
                if (res.length === 0) responseData = false;
                csvCompleteData.push(...res)
            })
            csvPage++
        } while (responseData)

        // Create the CSV content
        const csvContent = [
            ["Topup No", "Time", "Date", "Gross Topup", "Net Fees", "Net Topup", "USDT Rate", "USDT Net", "Ref"],
            ...csvCompleteData.map(({ txIndex, transaction_date, grossAmount, netFees, payoutBalance, usdtRate, usdtNet, remark }) => [
                txIndex,
                formatDateAndTime(transaction_date, true).formattedTime || '',
                formatDateAndTime(transaction_date, true).formattedDate || '',
                grossAmount,
                netFees,
                payoutBalance,
                usdtRate,
                usdtNet,
                remark || '',
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'topup.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }
    const calculatePayoutBalance = (grossAmount, fee, usdtRates) => {
        const fees = (fee / 100) * grossAmount; // Calculating fees based on percentage
        const net = grossAmount - fees; // Calculating net volume
        setNetFees(fees)
        setPayoutBalance(net);
        calculateNetUSDT(usdtRates, net)
    }
    const calculateNetUSDT = (rate, grossAmount) => {
        const net = (grossAmount !== undefined && grossAmount !== '') ? parseFloat(grossAmount) : 0;
        const usdtRate = (rate !== undefined && rate !== '') ? parseFloat(rate) : 0;

        if (usdtRate === 0) {
            // Handle division by zero case
            setUsdtNet(0);
        } else {
            const netUSDT = net / usdtRate; // Calculating net USDT based on rate
            setUsdtNet(netUSDT);
        }
    }

    useEffect(() => {
        setAllDataLoading(true)
        getPayoutVolume().then(() => {
            setAllDataLoading(false)
        })
    }, [state, sandbox])
    useEffect(() => {
        getTopupLogs()
    }, [state, page, sandbox])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        <MDBox mt={1}>
                            {data && <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                <Grid xl={3}>
                                    <DefaultProjectCard
                                        business={data?.business_name}
                                        email={data?.emailId}
                                        name={`${data?.first_name} ${data?.last_name}`}
                                        btn={setOpenDialog}
                                        type="topup"
                                    />
                                </Grid>
                                <Grid xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="7FDF9A"
                                                    title="Today's Payout"
                                                    count={Number(data?.last24hr).toFixed(0) || ''}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="FFFED2"
                                                    title="Yesterday's Payout"
                                                    count={`${Number(data?.yesterday).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="D4BEF0"
                                                    title="Total Payout"
                                                    count={`${Number(data?.totalPayout).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="BBF2EA"
                                                    title="Total Topup"
                                                    count={`${Number(data?.totalTopup).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="C9DEDE"
                                                    title="Payout Wallet"
                                                    count={`${Number(data.payoutBalance).toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {/* topupLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={12}>
                                        <MDBox display={'flex'} flexDirection={'row'}>
                                            <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Topup Logs</MDTypography>
                                            <MDBox pl={3}>
                                                {
                                                    loading ? (<BeatLoader
                                                        color="#36d7b7"
                                                        cssOverride={{}}
                                                        size={10}
                                                    />) : (
                                                        <FileDownloadOutlinedIcon onClick={() => downloadTopupLogs()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                    )
                                                }

                                            </MDBox>
                                        </MDBox>
                                    </Grid>

                                    <TopupLogs pages={page} pageSetter={setPage} logs={topupLogs} downloadInvoice={downloadPdfInvoice} />

                                </Grid>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )
            }

            <Dialog PaperProps={{
                style: {
                    minHeight: '20vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    left: '50%', // Adjust the left value to move the dialog to the right
                },
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>TOPUPS</DialogTitle>
                <DialogContent>
                    <MDBox px={1} py={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="large" variant="caption" >
                            Payout Balance: {payoutBalance?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="large" variant="caption" >
                            Net Fees: {netFees?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontSize="large" fontWeight="medium" variant="caption" >
                            Usdt Net: {usdtNet?.toFixed(2)}
                        </MDTypography>
                    </MDBox>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleTopup}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field type="number" label="Gross Amount" as={MDInput} fullWidth name="grossAmount"
                                        onChange={(e) => {
                                            setFieldValue('grossAmount', e.target.value)
                                            calculatePayoutBalance(e.target.value, values.netFeesInPercentage, values.usdtRate)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="grossAmount" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="number" label="Fees %" as={MDInput} fullWidth name="netFeesInPercentage"
                                        onChange={(e) => {
                                            setFieldValue('netFeesInPercentage', e.target.value)
                                            calculatePayoutBalance(values.grossAmount, e.target.value, values.usdtRate)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="netFeesInPercentage" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="number" label="USDT Rate" as={MDInput} fullWidth name="usdtRate"
                                        onChange={(e) => {
                                            setFieldValue('usdtRate', e.target.value)
                                            calculateNetUSDT(e.target.value, payoutBalance)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="usdtRate" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="text" label="Ref" as={MDInput} fullWidth name="ref" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="ref" component="div" />
                                    </MDTypography>
                                </MDBox>
                                {/* <MDBox mb={2}>
                                    <Field type="text" label="Notes" as={MDInput} fullWidth name="notes" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="notes" component="div" />
                                    </MDTypography>
                                </MDBox> */}
                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Topping up...' : 'Topup Merchant'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog>
        </DashboardLayout>
    )
}

export default topupMerchant