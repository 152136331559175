/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultNavbarLink({ icon, name, route, light, clickAction, componentSetter, componentName }) {
  return (
    <>
      {
        clickAction !== "" ? (
          <MDBox
            component={Link}
            to={route}
            mx={1}
            p={1}
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer", userSelect: "none" }}
            onClick={() => clickAction(true)}
          >
            <Icon
              sx={{
                color: ({ palette: { white, secondary } }) => (light ? white.main : secondary.main),
                verticalAlign: "middle",
              }}
            >
              {icon}
            </Icon>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color={light ? "white" : "dark"}
              textTransform="capitalize"
              sx={{ width: "100%", lineHeight: 0 }}
            >
              &nbsp;{name}
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox
            component={Link}
            to={route}
            mx={1}
            p={1}
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer", userSelect: "none" }}
            onClick={() => componentSetter(componentName)}
          >
            <Icon
              sx={{
                color: ({ palette: { white, secondary } }) => (light ? white.main : secondary.main),
                verticalAlign: "middle",
              }}
            >
              {icon}
            </Icon>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color={light ? "white" : "dark"}
              textTransform="capitalize"
              sx={{ width: "100%", lineHeight: 0 }}
            >
              &nbsp;{name}
            </MDTypography>
          </MDBox>
        )
      }
    </>

  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string,
  light: PropTypes.bool.isRequired,
  clickAction: PropTypes.oneOfType([
    PropTypes.func, // Function type
    PropTypes.string // String type
  ]),
  componentSetter: PropTypes.func,
  componentName: PropTypes.string
};

export default DefaultNavbarLink;
