import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../DataTable';
import dataFormat from "layouts/merchantTopup/daily-logs/logsv2/data";
function logsV2(props) {
    const { pages, pageSetter, data } = props
    const { columns, rows } = dataFormat(data);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            pages={pages}
            pageSetter={pageSetter}
        />
    );
}

logsV2.propTypes = {
    pages: PropTypes.number,
    pageSetter: PropTypes.func,
    data: PropTypes.array
};

export default logsV2;
