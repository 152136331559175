
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { Grid } from '@mui/material';
// import { makeStyles } from "@mui/styles"
import Loader from "util/Loader";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
// import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
// import { useUser } from 'ZustandState/useUser';
import { useConfig } from "../../../config";
import { useSandbox } from "ZustandState/useSanbox";
import MerchantInformation from "layouts/merchant/allMerchants/merchant-info";
function merchants() {
    const [merchant, setMerchants] = useState([])
    const { apiUrl, apiKey } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminToken = localStorage.getItem('gpay_admin_token')
    const [loading, setLoading] = useState(true)
    async function getAllMerchants() {
        try {
            const response = await fetch(`${apiUrl}/admin/getAllMerchants?limit=100&skip=0`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'apikey': apiKey,
                    'Content-Type': 'application/json',
                },
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            setMerchants(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    useEffect(() => {
        setLoading(true)
        getAllMerchants().then(() => {
            setLoading(false)
        })
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={3}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={5}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Merchants
                        </MDTypography>
                    </MDBox>

                    {/* test */}
                    <MDBox mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                {
                                    !loading && (<MerchantInformation allMerchants={merchant} getAllMerchantsfunc={getAllMerchants} />)
                                }
                            </Grid>
                        </Grid>
                    </MDBox>

                </Card>
                {
                    loading && (
                        <Loader />
                    )
                }

            </MDBox>
        </DashboardLayout>

    )
}

export default merchants