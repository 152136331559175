import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { useMaterialUIController } from 'context'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import { useConfig } from "../../../config"
import Logs from "layouts/merchantSettlements/daily-logs/logsv2"
import { getDayOfWeek } from 'util/formatDateAndTime'
import BeatLoader from "react-spinners/BeatLoader";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Loader from 'util/Loader'
import formatDateAndTime from 'util/formatDateAndTime'
import { useSandbox } from 'ZustandState/useSanbox'
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import CallReceived from '@mui/icons-material/CallReceived';
// import CallMade from '@mui/icons-material/CallMade';
function dailyLogs() {
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const { state } = useLocation()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [cards, setCards] = useState()
    const [page, setPage] = useState(0)
    const [logs, setLogs] = useState([])
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    // const [value, setValue] = React.useState(0);
    const limit = 10
    // for upper cards 
    const getMerchant24hrData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state.emailId
            }
            console.log(body)
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getlast24hourdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setCards(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    // for all daily logs of this merchant by pagination for table
    const getMerchantAllLogs = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            email_Id: state?.emailId || '',
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getmerchantlogs`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Logs: ", err)
        }
    }

    const getMerchantAllLogsForCsv = async (body) => {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantlogs`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const downloadMerchantLogs = async () => {
        setLoading(true)
        setCsvCompleteData([])
        let csvPage = 0
        let responseData = true
        do {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state?.emailId || '',
                limit: 500,
                skip: csvPage * 500,
            }
            await getMerchantAllLogsForCsv(body).then((res) => {
                if (res.length === 0) responseData = false;
                csvCompleteData.push(...res)
            })
            csvPage++
        } while (responseData)

        // Create the CSV content
        const csvContent = [
            ["Date", "Day", "Gross Volume", "Tx Count"],
            ...csvCompleteData.map(({ date, volume, transactionCount }) => [
                formatDateAndTime(date).formattedDate,
                getDayOfWeek(date.split('T')[0]),
                volume,
                transactionCount
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${state.business_name}Logs.csv`

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }

    useEffect(() => {
        getMerchantAllLogs()
    }, [page, sandbox])

    // useEffect(() => {
    //     setAllDataLoading(true)
    //     if (value === 0) {
    //         getMerchantAllLogs().then(() => {
    //             setAllDataLoading(false)
    //         })
    //     } else {
    //         getMerchantPayoutLogs().then(() => {
    //             setAllDataLoading(false)
    //         })
    //     }
    // }, [value])

    useEffect(() => {
        setAllDataLoading(true)
        getMerchant24hrData().then(() => {
            setAllDataLoading(false)
        })
    }, [sandbox])


    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     setPage(0)
    // };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        {cards && <Grid container spacing={3}>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="7FDF9A"
                                        title="Today's Volume"
                                        count={Number(cards?.last24hr).toFixed(0)}

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="D4BEF0"
                                        title="Successful transactions"
                                        count={cards?.successfulTransactions}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="BBF2EA"
                                        title="Success Rate"
                                        count={`${Math.round(cards?.successRate)}%`}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="FFFED2"
                                        title="Yesterday's Volume"
                                        count={Number(cards?.yesterday).toFixed(0)}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="C9DEDE"
                                        title="Balance Available"
                                        count={Number(cards?.balance).toFixed(0)}
                                    />
                                </MDBox>
                            </Grid>
                        </Grid>}

                        <MDBox mt={3}>
                            <MDBox display={'flex'} flexDirection={'row'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12} xl={12}>
                                        <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Daily Logs</MDTypography>
                                        <MDBox display={'flex'} flexDirection={'row'} alignItems="center">
                                            <MDTypography variant="caption" color={darkMode ? "white" : "black"}>Business: {state?.business_name}</MDTypography>
                                            <MDBox pl={3}>
                                                {
                                                    loading ? (<BeatLoader
                                                        color="#36d7b7"
                                                        cssOverride={{}}
                                                        size={10}
                                                    />) : (
                                                        <FileDownloadOutlinedIcon onClick={() => downloadMerchantLogs()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                    )
                                                }
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>
                        {/* <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="icon position tabs example"
                        >
                            <Tab sx={{ fontWeight: "medium" }} icon={<CallReceived />} iconPosition="start" label="Payins" />
                            <Tab sx={{ fontWeight: "medium" }} icon={<CallMade />} iconPosition="end" label="Payouts" />

                        </Tabs> */}
                        <MDBox
                            mt={0.5}
                        >
                            <Logs pages={page} pageSetter={setPage} data={logs} />
                        </MDBox>
                    </MDBox>
                )
            }

        </DashboardLayout>
    )
}

export default dailyLogs