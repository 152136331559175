
import Dashboard from "layouts/dashboard";
import PayinTransaction from "layouts/transactions";
import PayoutTransaction from "layouts/payout-transaction";
import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password/cover";
import ResetMerchantPassword from "layouts/merchant/allMerchants/resetPassword";
import Logout from "layouts/authentication/logout";
import AddMerchant from "layouts/merchant/addMerchant"
import ExchangeRate from "layouts/exchangeRate"
import AddGateway from "layouts/allGateway/add-gateway"
// import MerchantTransaction from "./layouts/merchant/merchant-transactions"
import MerchantSettlements from "./layouts/merchantSettlements"
import MerchantTopups from "./layouts/merchantTopup"
import GatewaySettlement from "./layouts/gateway-settlement"
import AddBalance from "./layouts/merchant/addBalance"
import Gatways from "./layouts/allGateway"
// import SettleMerchant from "layouts/merchant/settleMerchant";
import Merchants from "layouts/merchant/allMerchants"
import Support from "layouts/support"
import Sales from "layouts/sales/allSales"
import AddSales from "layouts/sales/addSales"
import EditMerchant from "layouts/merchant/allMerchants/edit-merchant"
import UpdateRate from "layouts/exchangeRate/updateRate"
import EditSales from "layouts/sales/editSales"
import Stats from "layouts/stats"
import Report from "layouts/report"
import SettleMerchant from "layouts/merchantSettlements/settleMerchant"
import TopupMerchant from "layouts/merchantTopup/topupMerchant"
import SettleGateway from "layouts/gateway-settlement/settleGateway"
import ViewTransactions from "layouts/merchantSettlements/viewTransactions"
import PayoutDash from "layouts/payoutDash"
import DailyLogs from "layouts/merchantSettlements/daily-logs"
import DailyLogsPayout from "layouts/merchantTopup/daily-logs"
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Payin Transactions",
    key: "payin-transactions",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/payin-transactions",
    component: <PayinTransaction />,
  },
  {
    type: "collapse",
    name: "Payout Transactions",
    key: "payout-transactions",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/payout-transactions",
    component: <PayoutTransaction />,
  },
  // {
  //   type: "collapse",
  //   name: "Payouts",
  //   key: "payouts",
  //   icon: <Icon fontSize="small">arrow_outward</Icon>,
  //   route: "/payouts",
  //   component: <PayoutDash />,
  // },
  // {
  //   type: "collapse",
  //   name: "Overview",
  //   key: "overview",
  //   icon: <Icon fontSize="small">timeline</Icon>,
  //   route: "/overview",
  //   component: <Stats />,
  // },
  // {
  //   type: "collapse",
  //   name: "Report",
  //   key: "report",
  //   icon: <Icon fontSize="small">summarize</Icon>,
  //   route: "/report",
  //   component: <Report />,
  // },
  {
    type: "collapse",
    name: "Merchant Settlements",
    key: "merchant-settlements",
    icon: <Icon fontSize="small">checklist</Icon>,
    route: "/settlements",
    component: <MerchantSettlements />,
  },
  {
    type: "collapse",
    name: "Merchant Topups",
    key: "merchant-topups",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/merchant-topups",
    component: <MerchantTopups />,
  },
  {
    type: "collapse",
    name: "Merchants",
    key: "merchant",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/merchants",
    component: <Merchants />,
  },

  // {
  //   type: "collapse",
  //   name: "Gateways",
  //   key: "gateways",
  //   icon: <Icon fontSize="small">account_balance</Icon>,
  //   route: "/gateways",
  //   component: <Gatways />,
  // },
  // {
  //   type: "collapse",
  //   name: "Gateway Settlements",
  //   key: "gateway-settlements",
  //   icon: <Icon fontSize="small">payments</Icon>,
  //   route: "/gateway-settlements",
  //   component: <GatewaySettlement />,
  // },


  // // {
  // //   type: "collapse",
  // //   name: "Merchant Transactions",
  // //   key: "merchant-transactions",
  // //   icon: <Icon fontSize="small">receipt_long</Icon>,
  // //   route: "/merchant-transactions",
  // //   component: <MerchantTransaction />,
  // // },

  {
    type: "collapse",
    name: "Register Merchant",
    key: "register-merchant",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/add-merchant",
    component: <AddMerchant />,
  },
  {
    type: "collapse",
    name: "Add Gateway",
    key: "add-gateway",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/add-gateway",
    component: <AddGateway />,
  },
  {
    type: "collapse",
    name: "Exchange Rate",
    key: "exchange-rate",
    icon: <Icon fontSize="small">attach_money</Icon>,
    route: "/exchange-rate",
    component: <ExchangeRate />,
  },
  // {
  //   type: "collapse",
  //   name: "Tech Support",
  //   key: "support",
  //   icon: <Icon fontSize="small">record_voice_over</Icon>,
  //   route: "/support",
  //   component: <Support />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sales",
  //   key: "sales",
  //   icon: <Icon fontSize="small">supervisor_account</Icon>,
  //   route: "/sales",
  //   component: <Sales />,
  // },
  // {
  //   type: "collapse",
  //   name: "Add Balance",
  //   key: "add-balance",
  //   icon: <Icon fontSize="small">wallet</Icon>,
  //   route: "/add-balance",
  //   component: <AddBalance />,
  // },
  // // {
  // //   type: "collapse",
  // //   name: "Billing",
  // //   key: "billing",
  // //   icon: <Icon fontSize="small">receipt_long</Icon>,
  // //   route: "/billing",
  // //   component: <Billing />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "RTL",
  // //   key: "rtl",
  // //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  // //   route: "/rtl",
  // //   component: <RTL />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "Notifications",
  // //   key: "notifications",
  // //   icon: <Icon fontSize="small">notifications</Icon>,
  // //   route: "/notifications",
  // //   component: <Notifications />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "Profile",
  // //   key: "profile",
  // //   icon: <Icon fontSize="small">person</Icon>,
  // //   route: "/profile",
  // //   component: <Profile />,
  // // },
  {
    type: "collapse",
    name: "Log Out",
    key: "Log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/logout",
    component: <Logout />,
  },
  // {
  //   // type: "collapse",
  //   // name: "Edit Merchant",
  //   key: "edit-merchant",
  //   // icon: <Icon fontSize="small">Edit Merchant</Icon>,
  //   route: "/edit-merchant",
  //   component: <EditMerchant />,
  // },
  {
    // type: "collapse",
    // name: "Edit Merchant",
    key: "update-rate",
    // icon: <Icon fontSize="small">Edit Merchant</Icon>,
    route: "/update-rate",
    component: <UpdateRate />,
  },
  // {
  //   // type: "collapse",
  //   // name: "Edit Sales",
  //   key: "edit-sales",
  //   // icon: <Icon fontSize="small">Edit Merchant</Icon>,
  //   route: "/edit-sales",
  //   component: <EditSales />,
  // },
  // {
  //   // type: "collapse",
  //   // name: "Edit Merchant",
  //   key: "add-sales",
  //   // icon: <Icon fontSize="small">Edit Merchant</Icon>,
  //   route: "/add-sales",
  //   component: <AddSales />,
  // },
  // {
  //   // type: "collapse",
  //   // name: "Edit Merchant",
  //   key: "reset-merchant-password",
  //   // icon: <Icon fontSize="small">Edit Merchant</Icon>,
  //   route: "/reset-password",
  //   component: <ResetMerchantPassword />,
  // },
  {
    //type: "collapse",
    //name: "Reset Password",
    key: "change-password",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/change-password",
    component: <ResetPassword />,
  },
  {
    //type: "collapse",
    //name: "Sign In",
    key: "sign-in",
    //icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  // {
  //   //type: "collapse",
  //   key: "merchant-transaction",
  //   route: "/merchant-transaction",
  //   component: <Tables />,
  // },
  {
    key: "settle-merchant",
    route: "/settle-merchant",
    component: <SettleMerchant />,
  },
  {
    key: "topups",
    route: "/topups",
    component: <TopupMerchant />,
  },
  // {
  //   key: "settle-gateway",
  //   route: "/settle-gateway",
  //   component: <SettleGateway />,
  // },
  // {
  //   key: "view-transaction",
  //   route: "/view-transaction",
  //   component: <ViewTransactions />,
  // },
  {
    key: "daily-logs",
    route: "/daily-logs",
    component: <DailyLogs />,
  },
  {
    key: "daily-logs-payout",
    route: "/daily-logs-payout",
    component: <DailyLogsPayout />,
  },
  // // {
  // //   //type: "collapse",
  // //   key: "settle-merchant",
  // //   route: "/merchant-transactions/settle-merchant",
  // //   component: <SettleMerchant />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "Sign Up",
  // //   key: "sign-up",
  // //   icon: <Icon fontSize="small">assignment</Icon>,
  // //   route: "/authentication/sign-up",
  // //   component: <SignUp />,
  // // },
];

export default routes;
