/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import Badge from "@mui/material/Badge";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import MDInput from "components/MDInput";
import { useUser } from 'ZustandState/useUser';
import { useConfig } from "../../../config";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Switch from "@mui/material/Switch";
function Gateway({ gatewayName, abbr, collectionFee, payoutFee, noGutter, func, gatewayStatus }) {
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const user = useUser((state) => state.user)
    const [status, setStatus] = useState(gatewayStatus);
    const initialGatewayFeeValue = {
        emailId: adminEmail || '',  // admin emailId from state
        apiKey: adminApiKey || '',  // admin apiKey from state 
        gatewayName: gatewayName,
        collectionFee: collectionFee,
        payoutFee: payoutFee
    }
    const validationSchema = Yup.object({
        collectionFee: Yup.number().max(100).required('Collection Fee is required'),
        payoutFee: Yup.number().max(100).required('Payout Fee is required'),
    });
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const handleUpdateGatewayFee = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updategatewayfees`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: user ? user.emailId : '',
                apiKey: user ? user.apiKey : ''
            }
            func(adminbody)
            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });

        } catch (error) {
            //Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            // Reset submitting state
            setSubmitting(false);
        }
    }

    const handleUpdateGatewayStatus = async (body) => {
        try {
            setStatus((prevIsOn) => !prevIsOn);
            if (body.emailId === '' || body.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/setgatewayswitch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                setStatus((prevIsOn) => !prevIsOn);
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            func(adminbody)
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });

        } catch (error) {
            //Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

        }
    }


    return (

        <MDBox
            m={1}
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={darkMode ? "dark" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={noGutter ? 0 : 1}
            mt={2}
            width="48%"
        >
            <MDBox width="100%" display="flex" flexDirection="column">
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    mb={2}
                >
                    <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        {abbr || ''}
                    </MDTypography>

                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={3}
                        lineHeight={1}
                    >
                        <MDTypography variant="h6">Off/On</MDTypography>

                        <Switch checked={status} onChange={() => {
                            handleUpdateGatewayStatus({
                                emailId: adminEmail || '',
                                apiKey: adminApiKey || '',
                                gatewayName: gatewayName,
                                switch: status === false ? true : false
                            })
                        }} />
                    </MDBox>
                </MDBox>

                {/* Gateway form */}

                <MDBox >
                    <Formik
                        initialValues={initialGatewayFeeValue}
                        validationSchema={validationSchema}
                        onSubmit={handleUpdateGatewayFee}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2} display={'flex'} flexDirection={'column'}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <MDBox
                                            mt={2}
                                        >
                                            <Field
                                                type="text"
                                                label="Collection Fee"
                                                as={MDInput}
                                                fullWidth
                                                name="collectionFee"
                                            >
                                            </Field>
                                            <MDTypography color="error" variant="caption">
                                                <ErrorMessage name="collectionFee" component="div" />
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox
                                            mt={2}
                                        >
                                            <Field
                                                type="text"
                                                label="Payout Fee"
                                                as={MDInput}
                                                fullWidth
                                                name="payoutFee"
                                            >
                                            </Field>
                                            <MDTypography color="error" variant="caption">
                                                <ErrorMessage name="payoutFee" component="div" />
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>

                                    {/* Button */}
                                    <Grid item xs={6} lg={6} md={12} sm={12} justifyContent={'center'}>
                                        <MDBox
                                            ml={27}
                                        >
                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                type="submit"
                                                disabled={isSubmitting}

                                            >
                                                {isSubmitting ? 'Updating...' : 'Update'}
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </MDBox>


            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of Merchant
Gateway.defaultProps = {
    noGutter: false
};

// Typechecking props for the Merchant
Gateway.propTypes = {
    gatewayName: PropTypes.string.isRequired,
    abbr: PropTypes.string.isRequired,
    collectionFee: PropTypes.string.isRequired,
    payoutFee: PropTypes.string.isRequired,
    noGutter: PropTypes.bool,
    func: PropTypes.func,
    gatewayStatus: PropTypes.bool
};

export default Gateway;
