/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
// import Badge from "@mui/material/Badge";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";
import { useConfig } from "../../../../config";
import { toast } from "react-toastify";
function Sales({ email, noGutter, name, merchants, getAllSalesFunc }) {
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    async function deleteSales() {
        try {
            let body = {
                emailId: adminEmail,
                apiKey: adminApiKey,
                email_Id: email
            }
            const response = await fetch(`${apiUrl}/admin/deleteSales`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            if (res.responseCode === 200) {
                toast.success(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
            }
            let newBody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            getAllSalesFunc(newBody)
        } catch (err) {
            console.log("Error deleteSales: ", err)
        }
    }
    return (

        <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={darkMode ? "transparent" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={noGutter ? 0 : 1}
            mt={2}
        >
            <MDBox width="100%" display="flex" flexDirection="column">
                {/* {
                    premium === "true" ? (
                        <Badge
                            badgeContent="Premium"
                            classes={{ badge: classes.customBadge }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                        </Badge>
                    ) : (null)
                } */}

                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    mb={2}
                >
                    <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        Sales Agent:&nbsp;&nbsp;&nbsp;  {name}
                    </MDTypography>

                    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                        <MDBox mt={3} mr={1}>
                            <MDButton variant="h2" style={{ textAlign: 'center' }}>
                                <Link to={'/edit-sales'} state={{ email, merchantsList: merchants, name }} style={{ color: darkMode ? 'white' : 'black', textDecoration: 'none' }}>
                                    <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                        <Grid item>
                                            <Icon fontSize="medium">edit</Icon>
                                        </Grid>
                                        <Grid item>
                                            Edit Sales Agent
                                        </Grid>
                                    </Grid>
                                </Link>
                            </MDButton>


                            <MDButton onClick={deleteSales} variant="h2" style={{ color: 'red', textAlign: 'center' }}>
                                <Icon fontSize="medium" style={{ color: 'red' }}>delete</Icon>&nbsp;Delete
                            </MDButton>


                        </MDBox>
                        {/* <MDButton variant="text" color={isBanned ? "success" : "error"}>
                            <div
                                onClick={() =>
                                    handleUpdateBanStatus({
                                        emailId: adminEmail || '',
                                        apiKey: adminApiKey || '',
                                        email_Id: email,
                                        isBanned: isBanned === false ? true : false
                                    })
                                }
                            >
                                <Icon>
                                    {isBanned === false ? <Icon>not_interested</Icon> : <Icon>check</Icon>}
                                </Icon>
                                {isBanned === false ? 'Ban Merchant' : 'Unban Merchant'}
                            </div>
                        </MDButton> */}
                    </MDBox>
                </MDBox>
                <Grid container spacing={2} display={'flex'} flexDirection={'row'}>
                    <Grid item xs={6}>
                        <MDBox mb={1} lineHeight={1}>
                            <MDTypography fontWeight="medium" variant="caption" color="text">
                                Email Address:&nbsp;&nbsp;&nbsp;
                                <MDTypography variant="caption" fontWeight="medium">
                                    {email}
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                        <MDBox mb={1} lineHeight={1}>
                            <MDTypography fontWeight="medium" variant="caption" color="text">
                                Merchants:&nbsp;&nbsp;&nbsp;
                                {
                                    merchants && merchants.map((merchant, index) => (
                                        <MDTypography key={index} variant="caption" fontWeight="medium">
                                            {merchant?.business_name} |
                                        </MDTypography>
                                    ))
                                }

                            </MDTypography>
                        </MDBox>
                    </Grid>


                </Grid>
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of Merchant
Sales.defaultProps = {
    noGutter: false
};

// Typechecking props for the Merchant
Sales.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    merchants: PropTypes.array,
    noGutter: PropTypes.bool,
    getAllSalesFunc: PropTypes.func
};

export default Sales;
