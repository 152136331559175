
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import dataFormat from "layouts/exchangeRate/history/data";
function HistoryOverview(props) {
    const { list, page, pageSetter } = props
    const { columns, rows } = dataFormat(list);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            pages={page}
            pageSetter={pageSetter}
        />
    );
}

HistoryOverview.propTypes = {
    list: PropTypes.array,
    page: PropTypes.number,
    pageSetter: PropTypes.func
};

export default HistoryOverview;
