import moment from 'moment-timezone';
export default function formatDateAndTime(dateString, isCsv = false) {
  // Convert the timestamp to an integer if it's a string
  const timestamp = parseInt(dateString, 10);

  // Get the user's local timezone
  const localTimeZone = moment.tz.guess();

  // Create a moment object with the timestamp and local timezone
  const date = moment.tz(timestamp, localTimeZone);

  // Format the date and time in the user's local timezone
  let formattedDate = isCsv ? date.format('MMM DD YYYY') : date.format('MMM DD, YYYY'); // e.g., "Sep 17, 2023"
  const formattedTime = date.format('hh:mm:ss A'); // e.g., "04:40:55 AM"

  return { formattedDate, formattedTime };
}

export function getDayOfWeek(dateString, timeZone = "Asia/Kolkata") {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Parse the input date string using moment-timezone
  const momentDate = moment.utc(dateString);

  // Set the time zone
  momentDate.tz(timeZone);

  const dayOfWeekIndex = momentDate.day(); // 0 for Sunday, 1 for Monday, and so on
  const dayOfWeek = daysOfWeek[dayOfWeekIndex];

  return dayOfWeek;
}

export function capitalizeFirstLetter(word) {
  // Check if the input is a valid string
  if (typeof word !== 'string' || word.length === 0) {
    return word;
  }

  // Capitalize the first letter and concatenate the rest of the word
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function convertTo12HourFormat(timeStr) {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeStr.split(':').map(Number);

  // Determine if it's AM or PM
  const period = hours < 12 ? 'Am' : 'Pm';

  // Convert to 12-hour format
  const adjustedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

  // Format the result
  const result = `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

  return result;
}

export function hideDigits(number) {
  const lastFourDigits = number.slice(-4); // Get the last four digits
  const hiddenDigits = 'X'.repeat(number.length - 4); // Replace the rest of the digits with 'X'
  return hiddenDigits + lastFourDigits;
}