import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MerchantOverview from 'layouts/merchantTopup/merchantOverview'
import { useMaterialUIController } from 'context'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
import useAuthFetch from 'util/apiFetcher'
function merchantTopup() {
    const authFetch = useAuthFetch()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const sandbox = useSandbox((state) => state.sandbox)
    const [merchantList, setMerchantList] = useState([])
    const [loading, setLoading] = useState(true)
    const getMerchantList = async () => {
        try {
            const res = await authFetch(`/getActivePayoutMerchants`, {
                method: 'GET',
            }, false)
            if (!res) return;
            setMerchantList(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getMerchantList: ", err)
        }
    }
    useEffect(() => {
        setLoading(true)
        getMerchantList().then(() => {
            setLoading(false)
        })
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <MDBox mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Merchant Topups</MDTypography>
                            {
                                !loading && (
                                    <MDBox
                                        mt={2}
                                    >
                                        <MerchantOverview list={merchantList} />
                                    </MDBox>
                                )
                            }
                            {
                                loading && (
                                    <Loader />
                                )
                            }
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}

export default merchantTopup