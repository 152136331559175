import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import formatDateAndTime from "util/formatDateAndTime";
export default function data(historyList) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "Time", accessor: "time", width: "10%", align: "left" },
            { Header: "Date", accessor: "date", width: "10%", align: "left" },
            { Header: "Rate", accessor: "rate", width: "15%", align: "right" },
            { Header: "Notes", accessor: "notes", width: "15%", align: "left" },
            { Header: "", accessor: "empty", width: "50%", align: "right" },
        ],

        rows: historyList && historyList.map((item, index) => ({
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item?.date).formattedTime}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item?.date).formattedDate}
                </MDTypography>
            ),
            rate: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.usdtRate}
                </MDTypography>
            ),
            notes: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.notes}
                </MDTypography>
            ),
        }))
    }
}
