import { useConfig } from 'config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const useAuthFetch = () => {
    const { apiUrl, apiKey } = useConfig()
    const navigate = useNavigate()
    const authFetch = async (url, options = {}, sendSuccess = true) => {
        try {
            const adminToken = localStorage.getItem("gpay_admin_token");

            const defaultHeaders = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${adminToken}`,
                'apikey': apiKey,
            };

            const baseUrl = apiUrl + '/admin';
            const isLoginRoute = url.includes('/login');

            const headers = isLoginRoute ? options.headers : { ...defaultHeaders, ...options.headers };
            const config = {
                method: options.method || 'GET',
                headers: headers,
                body: options.body ? options.body : undefined,
                ...options,
            };

            const response = await fetch(baseUrl + url, config);
            const res = await response.json();

            if (res.responseCode === 403) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                localStorage.removeItem('gpay_admin_token');
                navigate('/authentication/sign-in')
                return;
            }

            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                return;
            }

            if (sendSuccess) {
                toast.success(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
            }

            return res;
        } catch (error) {
            if (sendSuccess) {

                toast.error('An error occurred. Please try again later.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                throw error;
            }
        }
    };

    return authFetch;
};

export default useAuthFetch;
