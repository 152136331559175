import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Icon, TableContainer } from '@mui/material';
import { useMaterialUIController } from 'context';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';
import formatDateAndTime from "util/formatDateAndTime";
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { capitalizeFirstLetter } from 'util/formatDateAndTime';
const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
        border: 'none', // Remove border for the entire table
    },
    cell: {
        borderBottom: 'none', // Remove the bottom border for the table cells
        padding: '6px', // Reduce cell padding to make them take up less space
        fontSize: '14px', // Reduce font size to make text smaller in cells
    },
    rightAlignedCell: {
        textAlign: 'right', // Right-align text in the cell
        paddingRight: '20px', // Adjust as needed
    },
    leftAlignedCell: {
        textAlign: 'left', // Right-align text in the cell
        paddingLeft: '110px', // Adjust as needed
    },
});

function GatewaySettlementLogs(props) {
    const { pages, pageSetter, logs } = props
    const [controller] = useMaterialUIController()
    const { classes } = props;
    const { darkMode } = controller

    return (
        <TableContainer className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: 'center' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">No.</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'left' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Time</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'left' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Date</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'left' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Gateway</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'center' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Gross Volume</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'center' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">GST</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'center' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Ref</MDTypography></TableCell>
                        <TableCell style={{ textAlign: 'left' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Notes</MDTypography></TableCell>
                        {/* <TableCell style={{ textAlign: 'center' }}><MDTypography fontWeight='medium' color={darkMode ? "white" : "black"} variant="caption">Invoice Download</MDTypography></TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs && logs.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell className={classes.cell} style={{ textAlign: 'center' }} component="th" scope="row">
                                <MDTypography fontWeight="medium" color={darkMode ? "white" : "black"} variant="caption"> {row?.index + 1}</MDTypography>
                            </TableCell>
                            <TableCell style={{ textAlign: 'left' }} className={classes.cell}><MDTypography color={darkMode ? "white" : "black"} variant="caption">{formatDateAndTime(row?.transaction_date).formattedTime || ''}</MDTypography></TableCell>
                            <TableCell style={{ textAlign: 'left' }} className={classes.cell}><MDTypography color={darkMode ? "white" : "black"} variant="caption">{formatDateAndTime(row?.transaction_date).formattedDate || ''}</MDTypography></TableCell>
                            <TableCell style={{ textAlign: 'left' }} className={classes.cell}><MDTypography color={darkMode ? "white" : "black"} variant="caption">{capitalizeFirstLetter(row?.gatewayName)}</MDTypography></TableCell>
                            <TableCell align='right' className={`${classes.cell} ${classes.rightAlignedCell}`}><MDTypography mr={4} color={darkMode ? "white" : "black"} variant="caption">{row?.amount ? row?.amount.toFixed(0) : ''}</MDTypography></TableCell>
                            <TableCell align='right' className={`${classes.cell} ${classes.rightAlignedCell}`}><MDTypography mr={5} color={darkMode ? "white" : "black"} variant="caption">{row?.gst}</MDTypography></TableCell>
                            <TableCell style={{ textAlign: 'left' }} className={classes.cell}><MDTypography ml={13} color={darkMode ? "white" : "black"} variant="caption">{row?.ref}</MDTypography></TableCell>
                            <TableCell style={{ textAlign: 'left' }} className={classes.cell}><MDTypography ml={2} color={darkMode ? "white" : "black"} variant="caption">{row?.notes}</MDTypography></TableCell>
                            {/* <TableCell style={{ textAlign: 'center' }} className={classes.cell}><MDBox color={darkMode ? "white" : "black"} variant="caption"><FileDownloadOutlinedIcon onClick={() => downloadInvoice(row)} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" /></MDBox></TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                mb={3}
                ml={1}
                mt={2}
            >
                <MDPagination
                //variant={pagination.variant ? pagination.variant : "gradient"}
                //color={pagination.color ? pagination.color : "info"}
                >
                    <MDPagination item onClick={() => { pages > 0 ? pageSetter(pages - 1) : null }}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                    </MDPagination>

                    <MDBox width="5rem" mx={1}>
                        <MDInput
                            inputProps={{ type: "number", min: 1, disabled: true }}
                            value={pages}
                        //onChange={(handleInputPagination, handleInputPaginationValue)}
                        />
                    </MDBox>
                    <MDPagination item onClick={() => { pageSetter(pages + 1) }}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                    </MDPagination>
                </MDPagination>
            </MDBox>
        </TableContainer>
    );
}

GatewaySettlementLogs.propTypes = {
    classes: PropTypes.object.isRequired,
    pages: PropTypes.number,
    pageSetter: PropTypes.func,
    logs: PropTypes.any,
};

export default withStyles(styles)(GatewaySettlementLogs);
